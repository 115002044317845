import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import bg from "../images/legal-bg.jpg"
import enkeli from "../images/enkeli.jpg"

const Tietosuojaseloste = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Tietosuojaseloste" />
    <div className="hero-area full-container">
      <div
        className="page-banner page-banner-image parallax"
        style={{ background: `url(${bg})` }}
      >
        <div className="container">
          <h1>Tietosuojaseloste</h1>
        </div>
      </div>
    </div>

    <div id="content" className="content full pt-10 pb-10">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-8 col-md-offset-2">
            <h1>Tietosuojaseloste</h1>
<p>Tämä on EU:n yleisen tietosuoja-asetuksen (679/2016) mukainen selvitys henkilötietojen käsittelystä.</p>
<h3>Rekisterinpitäjä</h3>
<p>
  Suomen hautaustoiminnan keskusliitto Begravningsverksamhetens centralförbund i Finland ry <br />
  Y-tunnus: 1480117-5<br />
  Osoite: Koivurinne 4, <br />
  18100 HEINOLA <br />
  Finland<br />
  p. 0400 618872<br />
  sähköposti: info@shk.fi<br />
</p>
<h3>Tietosuoja-asioita koskevat yhteydenotot</h3>
<p>
  Matti Halme<br />
  p. +358 40 061 8872<br />
  sähköposti: info@shk.fi<br />
</p>
<p>Kaikissa henkilötietojen käsittelyyn liittyvissä kysymyksissä ja omien oikeuksien käyttämiseen liittyvissä tilanteissa rekisteröityä kehotetaan ottamaan yhteyttä edellä mainittuun yhteyshenkilöön.</p>
<h3>Henkilörekisterin nimi</h3>
<p>Suomen Hautaustoiminnan Keskusliitto Ry:n henkilörekisteri</p>
<h3>Henkilötietojen käsittelyn peruste ja tarkoitus</h3>
<p>
  Henkilötietojen käsittelyn oikeusperusteena on:<br />
  - Rekisteröidyn antama suostumus henkilötietojen käsittelyyn<br />
  - Rekisteröidyn ja rekisterinpitäjän välinen sopimussuhde<br />
  - Rekisterinpitäjän lakisääteisten velvoitteiden toteuttaminen<br />
  Henkilötietojen käsittelyn tarkoituksiin kuuluvat asiakkuus-, työ- ja kumppanuussuhteiden hoitoon liittyvät asiat,
</p>
<h3>Säännönmukaiset tietolähteet</h3>
<p>Käsiteltävät henkilötiedot saadaan säännönmukaisesti rekisteröidyltä itseltään.</p>
<h3>Käsiteltävät henkilötiedot</h3>
<p>
  Rekisterinpitäjä kerää rekisteröidyistä vain sellaisia henkilötietoja, jotka ovat olennaisia ja tarpeellisia tässä tietosuojaselosteessa selostettujen käyttötarkoitusten kannalta.
  </p>
  <p>
  Rekisteröidyistä käsitellään seuraavia tietoja:<br />
  - Etunimi<br />
  - Sukunimi<br />
  - Henkilötunnus<br />
  - Osoitetiedot<br />
  - Puhelintiedot<br />
  - Pankin tilinumero<br />
</p>
<h3>Henkilötietojen luovuttaminen</h3>
<p>Henkilötietoja ei luovuteta ulkopuolisille, ellei laissa aseteta siihen velvoitetta. Tietoja voidaan siis poikkeuksellisesti luovuttaa esimerkiksi viranomaisille lain vaatimuksesta.</p>
<h3>Henkilötietojen siirrot kolmansiin maihin</h3>
<p>Henkilötietoja ei siirretä EU:n ja Euroopan talousalueen ulkopuolelle.</p>
<h3>Tietojen säilytysaika</h3>
<p>Rekisterinpitäjä käsittelee henkilötietoja vain tarvittavan ajan. Tämän ajan päätyttyä rekisterinpitäjä poistaa tai anonymisoi tiedot kuuden kuukauden kuluessa noudattamiensa poistoprosessien mukaisesti.</p>
<p>Rekisterinpitäjällä voi olla velvollisuus käsitellä joitakin rekisteriin kuuluvia henkilötietoja yllä todettua pitempään lainsäädännön tai viranomaisvaatimusten noudattamiseksi.</p>
<h3>Rekisteröidyn oikeudet</h3>
<p><i>Oikeus saada pääsy henkilötietoihin</i></p>
<p>Rekisteröidyllä on oikeus saada vahvistus siitä, käsitelläänkö häntä koskevia henkilötietoja, ja jos käsitellään, oikeus saada kopio henkilötiedoistaan.</p>
<p><i>Oikeus tietojen oikaisemiseen</i></p>
<p>Rekisteröidyllä on oikeus pyytää, että häntä koskevat epätarkat ja virheelliset henkilötiedot korjataan. Rekisteröidyllä on myös oikeus saada puutteelliset henkilötiedot täydennettyä toimittamalla tarvittavat lisätiedot.</p>
<p><i>Oikeus tietojen poistamiseen</i></p>
<p>Rekisteröidyllä on oikeus pyytää itseään koskevien henkilötietojen poistamista, jos</p> 
<p>
  a. henkilötietoja ei enää tarvita niihin tarkoituksiin, joita varten ne on kerätty;<br />
  b. rekisteröity peruuttaa suostumuksen, johon henkilötietojen käsittely on perustunut, eikä käsittelyyn ole muuta laillista perustetta; tai<br />
  c. henkilötietoja on käsitelty lainvastaisesti.
</p>
<p><i>Oikeus käsittelyn rajoittamiseen</i></p>
<p>Rekisteröidyllä on oikeus rajoittaa itseään koskevien henkilötietojen käsittelyä, jos</p> 
<p>
  a. rekisteröity kiistää henkilötietojensa paikkansapitävyyden;<br />
  b. käsittely on lainvastaista, ja rekisteröity vastustaa henkilötietojensa poistamista ja vaatii sen sijaan niiden käytön rajoittamista; tai<br />
  c. rekisterinpitäjä ei enää tarvitse henkilötietoja käsittelyn alkuperäisiin tarkoituksiin, mutta rekisteröity tarvitsee niitä oikeudellisen vaateen laatimiseksi, esittämiseksi tai puolustamiseksi.
</p>
<p><i>Oikeus peruuttaa suostumus</i></p>
<p>Rekisteröidyllä on oikeus peruuttaa käsittelyyn antamansa suostumus milloin tahansa tämän vaikuttamatta suostumuksen perusteella ennen tätä suoritetun käsittelyn lainmukaisuuteen.</p>
<p><i>Oikeus siirtää tiedot järjestelmästä toiseen</i></p>
<p>Rekisteröidyllä on oikeus saada häntä koskevat ja hänen itse toimittamansa henkilötiedot jäsennellyssä, yleisesti käytetyssä ja koneellisesti luettavassa muodossa ja oikeus siirtää kyseiset tiedot toiselle rekisterinpitäjälle.</p>
<p><i>Oikeus tehdä valitus valvontaviranomaiselle</i></p>
<p>Henkilötietoasioiden kansallisena valvontaviranomaisena on oikeusministeriön yhteydessä toimiva tietosuojavaltuutetun toimisto. Sinulla on oikeus saattaa asiasi valvontaviranomaisen käsiteltäväksi, jos katsot, että sinua koskevien henkilötietojen käsittelyssä rikotaan sitä koskevaa lainsäädäntöä.</p>
<h3>Tietosuojakäytäntöjen muuttaminen</h3>
<p>Rekisterinpitäjä kehittää toimintaansa jatkuvasti ja voi sen johdosta joutua tarpeen mukaan muuttamaan ja päivittämään tietosuojakäytäntöjään. Muutokset voivat perustua myös tietosuojaa koskevan lainsäädännön muuttumiseen.</p>
<p>Mikäli muutokset pitävät sisällään uusia tarkoituksia henkilötietojen käsittelylle tai muutoin muuttuvat merkittävästi, rekisterinpitäjä ilmoittaa niistä etukäteen ja pyytää tarvittaessa suostumuksen.</p>
          </div>
        </div>

        <div className="spacer-45" />
      </div>
    </div>
  </Layout>
)

export default Tietosuojaseloste
